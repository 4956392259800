export const config_module = {
  momentum: {
    base_url: 'https://api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-8LYffkoedq9JX8MxGkFBaQ',
    configToken: 'cGVwcGVyTWV0YURhdGE6aWhndFNRJV5RN3hy',
  },
  pepper: {
    base_url: 'https://api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-BSkoONxL11oD53d3N1TP2z',
    configToken: '077b69d04c5d423d8f048a1798b8474a',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'R5Z-56R-7R5Z',
      },
    },
    appStoreUrl: {
      android: 'https://play.google.com/store/apps/details?id=com.pepper.too&hl=en',
    },
  },
  otis: {
    base_url: 'https://api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-zh1z0V8eMW1scuAiJyhSdw',
    configToken: 'cGVwcGVyTWV0YURhdGE6SDcscHFCP20=',
  },
  visi: {
    base_url: 'https://api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-YsnGm2zeZxRhYaKm54nE8w',
    configToken: 'cGVwcGVyTWV0YURhdGE6cjcmIUUrIW1uPGdMcnEoTA==',
  },
  geeni: {
    base_url: 'https://api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-zBmmx34kTTScEw0GkZf2jR',
    recurlyGooglePay: {
      // These should correspond to values in API docs https://recurly.com/developers/reference/recurly-js/#google-pay-
      merchantId: 'TODO-SET-ME',
      environment: 'PRODUCTION',
    },
    configToken: 'cGVwcGVyTWV0YURhdGE6KSM8O0JxUDU5I0ssWDZmNA==',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: 'W4R-8K7-756Z',
      },
    },
    social: {
      google: {
        clientId: '964644197306-tb60pmhnclti50kp52mn511l2u0cr011.apps.googleusercontent.com',
      },
      apple: {
        redirectUriBase: 'https://account.merkurysmart.com',
        lrApiKey: 'af718c26-de15-478e-8598-d6b06141bca2',
        lrUri: 'https://geeni.hub.loginradius.com/RequestHandler.aspx',
      },
    },
    appStoreUrl: {
      ios: 'https://apps.apple.com/us/app/geeni/id1177386276',
      android: 'https://play.google.com/store/apps/details?id=com.merkuryinnovations.geeni&hl=en',
    },
  },
  notion: {
    base_url: 'https://prod.api.pepperos.io',
    oauth_url: 'https://oauth2.prod-multi.pepperos.io',
    context: 'prod',
    recurlyUrl: '',
    recurlyPublicKey: '',
    configToken: 'cGVwcGVyTWV0YURhdGE6cjY5KEB3N1g/PGVlRz81TA==',
  },
  nightowl: {
    base_url: 'https://prod.api.pepperos.io',
    context: 'prod',
    recurlyUrl: 'https://js.recurly.com/v4/recurly.js',
    recurlyPublicKey: 'ewr1-3WxZeSRQjP7MSfTSqnEmuj',
    configToken: 'cGVwcGVyTWV0YURhdGE6N1FZZVNwNiUyUEBm',
    analytics: {
      provider: 'clevertap',
      config: {
        accountId: '798-4WK-R96Z',
      },
    },
  },
};
